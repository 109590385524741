import { defineStore } from "pinia";

export const useStaticProductDataStore = defineStore("staticProductData", () => {
	const { getStaticDataGeneral } = usePMApi();
	const staticProductData = ref<{ [key: string]: any } | null>(null);

	async function updateStaticProductData() {
		if (!staticProductData.value) {
			const { response } = await getStaticDataGeneral();

			staticProductData.value = response?.data as { [key: string]: any } | null;
		}
	}

	return {
		staticProductData,
		updateStaticProductData,
	};
});

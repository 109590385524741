import { defineStore } from "pinia";
import type { OptionType, OrderType, filters, filtersEndpoint, filtersQueryParams } from "@/types/filters";

export const useFiltersStore = defineStore("filters", () => {
	const { staticProductData } = storeToRefs(useStaticProductDataStore());

	const orderOptions = ref<OrderType[]>([
		{
			title: "components.products_filters.filters_bar.order_options.date_desc",
			value: "date_desc",
			order: {
				"platform_catalog_products.created": "DESC",
			},
		},
		{
			title: "components.products_filters.filters_bar.order_options.date_asc",
			value: "date_asc",
			order: {
				"platform_catalog_products.created": "ASC",
			},
		},
	]);

	const patternOptions = computed((): OptionType[] =>
		staticProductData.value?.ColorPatterns?.map((option: any) => ({
			label: option.name,
			value: option.id,
			parentValue: "73938a4d-19af-4c05-a48a-67f5ec3f9eba",
			parentOption: "pattern",
			children: [],
		}))
	);

	const colorOptions = computed((): OptionType[] => [
		{
			label: "Yellow",
			value: "173625bf-4580-44ac-914b-a06fd84cbdfc",
		},
		{
			label: "Purple",
			value: "1748d796-cd4b-4f77-b2ed-871248f94677",
		},
		{
			label: "White",
			value: "329c9201-f3fb-4c10-bab3-70d2ee049d97",
		},
		{
			label: "Red",
			value: "4e2b181b-717c-46b4-8de1-ad6901611069",
		},
		{
			label: "Pink",
			value: "5cf2a7bb-9e9a-49d8-93d1-b5fded442be7",
		},
		{
			label: "Blue",
			value: "8941e80f-346c-4075-b659-ff4eb07d20df",
		},
		{
			label: "Orange",
			value: "c09a6e4f-6b6a-460d-bb25-1b3b766417ae",
		},
		{
			label: "Green",
			value: "c4619a0c-c180-4301-86ac-03734d48a027",
		},
		{
			label: "Black",
			value: "d20b9548-865c-492d-895d-f9464f1915b8",
		},
		{
			label: "Grey",
			value: "e710224f-c932-4345-a3d5-c62bc23b6f6c",
		},
	]);

	const colorTypeOptions = computed(() => [
		{
			label: "Pattern",
			value: "73938a4d-19af-4c05-a48a-67f5ec3f9eba",
			children: patternOptions.value,
		},
		{
			label: "Solid",
			value: "785578e7-4b37-49d0-a47c-e8f81874425d",
			children: [],
		},
	]);

	const priceOptions = ref<OptionType[]>([
		{
			label: "< $3.00",
			value: {
				min: 0,
				max: 3,
			},
			selected: false,
		},
		{
			label: "3.00 - $6.00",
			value: {
				min: 3,
				max: 6,
			},
			selected: false,
		},
		{
			label: "$6.00 - $9.00",
			value: {
				min: 6,
				max: 9,
			},
			selected: false,
		},
		{
			label: "$9.00 - $12.00",
			value: {
				min: 9,
				max: 12,
			},
			selected: false,
		},
		{
			label: "> $12.00",
			value: {
				min: 12,
				max: null,
			},
			selected: false,
		},
	]);

	const meterOptions = ref<OptionType[]>([
		{
			label: "< 10 meters",
			value: {
				min: 0,
				max: 10,
			},
			selected: false,
		},
		{
			label: "10 - 100 meters",
			value: {
				min: 10,
				max: 100,
			},
			selected: false,
		},
		{
			label: "100 - 500 meters",
			value: {
				min: 100,
				max: 500,
			},
			selected: false,
		},
		{
			label: "500 - 1000 meters",
			value: {
				min: 500,
				max: 1000,
			},
			selected: false,
		},
		{
			label: "> 1000 meters",
			value: {
				min: 1000,
				max: null,
			},
			selected: false,
		},
	]);

	// Check recursively that all children and their children and... are checked
	const checkIfAllChildrenSelected = (o: OptionType) => {
		if (o.children?.length) {
			return o.children?.every((child: OptionType) => {
				if (child.children?.length) {
					checkIfAllChildrenSelected(child);
				}

				return child.selected;
			});
		}

		return true;
	};

	// Check recursively that at least one children or their children and... is checked
	const checkIfSomeChildrenSelected = (o: OptionType) => {
		if (o.children?.length) {
			return o.children?.some((child: OptionType) => {
				if (child.children?.length) {
					checkIfSomeChildrenSelected(child);
				}

				return child.selected;
			});
		}

		return true;
	};

	const generateOptionsTree = (options: []): OptionType[] =>
		options?.map(
			(option: any): OptionType => ({
				label: option.text,
				value: option.id,
				children: option.children?.length ? generateOptionsTree(option.children) : [],
				parentValue: option.parent_id,
			})
		);

	const fiberOptions = computed((): OptionType[] => {
		return generateOptionsTree(staticProductData.value?.Fibers);
	});

	// Will transform a tree of options into a flat array of selected options to send to API
	const reduceFilterOptions = (optionsArray: OptionType[]) =>
		optionsArray.reduce((acc: OptionType[], curr: OptionType) => {
			// If a box is not checked, no need to look through the children
			if (!curr.selected) {
				return acc;
			}

			// If all children are recursively checked, add it to acc array and no not
			// keep on looking through the children
			if (curr.selected && checkIfAllChildrenSelected(curr)) {
				acc = acc.concat(curr);

				return acc;
			}
			// If at least some children is checked
			acc = acc.concat(reduceFilterOptions(curr.children || []));

			// There will never be none checked below a checked option

			return acc;
		}, [] as OptionType[]);

	const formatFiltersBase = (fil: filters): any => {
		if (!Object.keys(fil).length) {
			return {};
		}

		const formattedFilters: any = useCloneDeep(fil);

		// ColorType
		// If any patterns are selected, will NOT send the pattern colortype
		// Solid is a colortype : it is not linked to the color filter
		const filteredColorType = reduceFilterOptions(formattedFilters.color_type || []);
		formattedFilters.color_type = filteredColorType;

		formattedFilters.color = formattedFilters.color?.filter((option: OptionType) => option.selected);
		formattedFilters.price = formattedFilters.price?.filter((option: OptionType) => option.selected);
		formattedFilters.meter = formattedFilters.meter?.filter((option: OptionType) => option.selected);

		// FiberContent
		// Take the filters tree, flatten it, only keep the options that need
		// to be sent to endpoint and return an array of their value
		const filteredFiber = reduceFilterOptions(formattedFilters.fiber_content || []);

		formattedFilters.fiber_content = filteredFiber;

		return Object.keys(formattedFilters).reduce((acc: filters, curr: string) => {
			if (formattedFilters[curr]?.length) {
				acc[curr] = formattedFilters[curr];
			}

			return acc;
		}, {});
	};

	const formatFiltersForEndpoint = (filters: filters): filtersEndpoint => {
		const formattedFiltersBase = formatFiltersBase(filters);

		return Object.keys(formattedFiltersBase).reduce((acc: filtersEndpoint, curr: string) => {
			if (curr === "fiber_content" || curr === "color" || curr === "pattern") {
				acc[curr] = formattedFiltersBase[curr]?.map((item: OptionType) => item.value);
			} else if (curr === "color_type") {
				formattedFiltersBase[curr]?.forEach((item: OptionType) => {
					const value: string = item.value as string;

					if (item.parentOption) {
						acc[item.parentOption] = (acc[item.parentOption] || []).concat([value]);
					} else {
						acc[curr] = (acc[curr] || []).concat([value]);
					}
				});
			} else if (curr === "meter" || curr === "price") {
				// skip for now, we are not using BE filters for those at the moment,
				// and only filtering them in the FE
			} else {
				acc[curr] = formattedFiltersBase[curr];
			}

			return acc;
		}, {} as filtersEndpoint);
	};

	const formatFiltersForQueryParams = (filters: filters): filtersQueryParams => {
		const formattedFiltersBase = formatFiltersBase(filters);

		return Object.keys(formattedFiltersBase).reduce((acc: filtersQueryParams, curr: string) => {
			if (curr === "fiber_content" || curr === "color") {
				acc[curr] = (formattedFiltersBase[curr]?.map((item: OptionType) => item.value).join(",") as string) || "";
			} else if (curr === "price" || curr === "meter") {
				acc[curr] = formattedFiltersBase[curr]
					?.map((item: { label: string; selected: boolean; value: { min: number; max: number | null } }): string =>
						[item.value?.min, item.value?.max ?? null].join("-")
					)
					.join(",") as string;
			} else if (curr === "color_type") {
				formattedFiltersBase[curr]?.forEach((item: OptionType) => {
					const value: string = item.value as string;

					if (item.parentOption) {
						acc[item.parentOption] = acc[item.parentOption] ? `${acc[item.parentOption]},${value}` : value;
					} else {
						acc[curr] = acc[curr] ? `${acc[curr]},${value}` : value;
					}
				});
			} else {
				acc[curr] = formattedFiltersBase[curr].join(",");
			}

			return acc;
		}, {} as filtersQueryParams);
	};

	const populateOptionsTree = (
		selectedArray: string[],
		array: OptionType[],
		isParentSelected = false
	): OptionType[] => {
		return array?.map((obj: OptionType, _index, arr: OptionType[]): OptionType => {
			const someSiblingsSelected = arr?.some(sibling => selectedArray?.includes(sibling.value));

			let selected = selectedArray?.includes(obj.value);

			// If none of the siblings are selected and the parent is,
			// then all the siblings are considered selected
			if (!selected && isParentSelected && !someSiblingsSelected) {
				selected = true;
			}

			let children: OptionType[] = [];

			if (obj.children?.length) {
				children = populateOptionsTree(selectedArray, obj.children, selected);

				// On the way back up, if any children was selected, the option is selected
				// (but will visually be indeterminate)
				if (checkIfSomeChildrenSelected({ ...obj, children })) {
					selected = true;
				}
			}

			return {
				...obj,
				children,
				selected,
			};
		});
	};

	const getFiltersFromQueryParams = (queryParams: filtersQueryParams): filters => {
		const colorType = populateOptionsTree(
			(queryParams.color_type?.split(",") || []).concat(queryParams.pattern?.split(",") || []),
			useCloneDeep(colorTypeOptions.value)
		);

		const color = useCloneDeep(colorOptions.value).map((option: OptionType) => {
			const qp = queryParams.color?.split(",");
			const optionsStringValue: string = option.value as string;

			return {
				...option,
				selected: qp?.includes(optionsStringValue),
			};
		});

		const price = useCloneDeep(priceOptions.value).map((option: OptionType) => {
			const qp = queryParams.price?.split(",");
			const value = option.value as { min: number; max: number | null };
			const optionsStringValue = [value?.min, value?.max ?? null].join("-");

			return {
				...option,
				selected: qp?.includes(optionsStringValue),
			};
		});

		const meter = useCloneDeep(meterOptions.value).map((option: OptionType) => {
			const qp = queryParams.meter?.split(",");
			const value = option.value as { min: number; max: number | null };
			const optionsStringValue = [value?.min, value?.max ?? null].join("-");

			return {
				...option,
				selected: qp?.includes(optionsStringValue),
			};
		});

		const fiberContent = populateOptionsTree(
			queryParams.fiber_content?.split(",") || [],
			useCloneDeep(fiberOptions.value)
		);

		return {
			color_type: colorType,
			color,
			price,
			meter,
			fiber_content: fiberContent,
		};
	};

	return {
		getFiltersFromQueryParams,
		populateOptionsTree,
		formatFiltersForEndpoint,
		formatFiltersForQueryParams,
		orderOptions,
		colorTypeOptions,
		patternOptions,
		colorOptions,
		priceOptions,
		meterOptions,
		fiberOptions,
	};
});
